import Redirect from '@/components/utils/redirect';
import WebsiteLayout from '@/components/website/layout/website-layout';
import WebsitePage from '@/components/website/page';
import { useWebsite } from '@/contexts/website-context';

const Website: React.FC = () => {
  const { currentWebsite } = useWebsite();

  if (currentWebsite) {
    return (
      <WebsiteLayout>
        <WebsitePage page={currentWebsite?.currentPage} />
      </WebsiteLayout>
    );
  }

  return <Redirect href="/404" />;
};

export default Website;
