/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import dynamic from 'next/dynamic';
import {
  ImageBlock,
  HeaderBlock,
  HeroBlock,
  TeamBlock,
  AnnouncementsBlock,
  MarketDataBlock,
  AnnualReportsBlock,
  LatestAnnouncementsFeaturedBlock,
  LatestAnnouncementsBlock,
  InvestorPresentationsBlock,
  UpdatesBlock,
  DiscoverBlock,
  QuickLinksBlock,
} from '@leaf/ui';
import { Page, PageBySlugQuery } from '@/apollo/generated';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
const VideoBlock = dynamic(() => import('./dynamic/video'), {
  ssr: false,
});
const TextBlock = dynamic(() => import('./dynamic/text'), {
  ssr: false,
});
const DocumentsBlock = dynamic(() => import('./dynamic/documents'), {
  ssr: false,
});
const WelcomeMessageBlock = dynamic(() => import('./dynamic/welcome-message'), {
  ssr: false,
});

interface Props {
  allBlocks: Page['blocks'] | null;
  block: NonNullable<
    NonNullable<
      NonNullable<NonNullable<PageBySlugQuery>['pageBySlug']>['blocks']
    >[0]
  >;
  index: number;
}

const WebsitePageBlock: React.ComponentType<Props> = ({
  allBlocks,
  block,
  index,
}) => {
  const shouldRemoveTopSpacing = (
    allBlocks: Page['blocks'] | null,
    index: number
  ) => {
    if (!allBlocks) return false;
    if (index === 0) return true;
    const prevBlock = allBlocks[index - 1];
    const noGapWhenAfter = ['header', 'hero'];
    // if this block is after a block in the noGapWhenAfter list, return true  to remove top spacing
    if (prevBlock?.type && noGapWhenAfter.includes(prevBlock.type)) return true;
  };
  const shouldRemoveBottomSpacing = (
    allBlocks: Page['blocks'] | null,
    index: number
  ) => {
    if (!allBlocks) return false;
    if (index === allBlocks.length - 1) return true;
  };

  const {
    currentCompany: { featuresEnabled, hub, logoUrl, name: companyName },
    currentCompanyMarketKey: marketKey,
    currentCompanyTicker: listingKey,
    isUK,
    translate,
  } = useCurrentCompany();
  const { currentWebsite } = useWebsite();

  const { content, type } = block;

  const renderPageBlockContent = () => {
    switch (type) {
      case 'documents':
        // @ts-ignore
        return <DocumentsBlock content={content} />;

      case 'team':
        // @ts-ignore
        return <TeamBlock content={content} />;

      case 'header':
        return (
          <HeaderBlock
            // @ts-ignore
            content={content}
            index={index}
            nextBlockType={
              allBlocks && allBlocks[block.position as number]?.type
            }
            removeBottomSpacing={shouldRemoveBottomSpacing(allBlocks, index)}
            removeTopSpacing={shouldRemoveTopSpacing(allBlocks, index)}
          />
        );

      case 'hero':
        return (
          <HeroBlock
            // @ts-ignore
            content={content}
            index={index}
            removeBottomSpacing={shouldRemoveBottomSpacing(allBlocks, index)}
            removeTopSpacing={shouldRemoveTopSpacing(allBlocks, index)}
          />
        );

      case 'image':
        // @ts-ignore
        return <ImageBlock content={content} />;

      case 'text':
        // @ts-ignore
        return <TextBlock content={content} />;

      case 'video':
        // @ts-ignore
        return <VideoBlock content={content} />;

      case 'welcome_message':
        // @ts-ignore
        return <WelcomeMessageBlock content={content} />;

      case 'market_data':
        return (
          <MarketDataBlock
            // @ts-ignore
            content={content}
            isUK={isUK}
            listingKey={listingKey}
            marketKey={marketKey}
            themeColourAccent={currentWebsite?.themeColourAccent || ''}
            themeColourPrimary={currentWebsite?.themeColourPrimary || ''}
            translate={translate}
          />
        );

      case 'investor_presentations':
        return (
          <InvestorPresentationsBlock
            // @ts-ignore
            content={content}
            hub={hub}
            isUK={isUK}
            listingKey={listingKey}
            marketKey={marketKey}
          />
        );

      case 'announcements':
        return (
          <AnnouncementsBlock
            featuresEnabled={featuresEnabled}
            // @ts-ignore
            heading={content.heading ?? ''}
            hub={hub}
            isUK={isUK}
            listingKey={listingKey}
            marketKey={marketKey}
            translate={translate}
          />
        );

      case 'updates':
        return (
          <UpdatesBlock
            companyName={companyName}
            // @ts-ignore
            heading={content.heading ?? ''}
            hub={hub}
            listingKey={listingKey}
            logoUrl={
              currentWebsite?.logoSquareCloudinaryUrl ||
              currentWebsite?.logoCloudinaryUrl ||
              logoUrl
            }
            marketKey={marketKey}
            // @ts-ignore
            variant={content.variant}
          />
        );

      case 'quick_links':
        // @ts-ignore
        return <QuickLinksBlock content={content} />;

      case 'latest_announcements':
        // @ts-ignore
        return content.variant === 'with_featured' ? (
          <LatestAnnouncementsFeaturedBlock
            // @ts-ignore
            content={content}
            hub={hub}
            listingKey={listingKey}
            marketKey={marketKey}
            translate={translate}
          />
        ) : (
          <LatestAnnouncementsBlock
            // @ts-ignore
            content={content}
            hub={hub}
            listingKey={listingKey}
            marketKey={marketKey}
            translate={translate}
          />
        );

      case 'discover':
        // @ts-ignore
        return <DiscoverBlock content={content} hub={hub} />;

      case 'annual_reports':
        return (
          <AnnualReportsBlock
            // @ts-ignore
            content={content}
            hub={hub}
            isUK={isUK}
            listingKey={listingKey}
            marketKey={marketKey}
          />
        );

      default:
        return null;
    }
  };
  return <>{renderPageBlockContent()}</>;
};

export default WebsitePageBlock;
