import { useEffect } from 'react';
import Router from 'next/router';

interface Props {
  external?: boolean;
  href: string;
}

const Redirect: React.ComponentType<Props> = ({ external, href }) => {
  useEffect(() => {
    if (external) {
      window.location.replace(href);
    } else {
      Router.replace(href);
    }
  }, [external, href]);

  return null;
};

export default Redirect;
